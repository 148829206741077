import React, { createRef, RefObject, useContext, useEffect, useState } from 'react';

import './page.scss';
import { Header } from '../../components/Header';
import { Dropdown } from '../../components/Dropdown';
import { MenuItem, MenuItemOnClickFunction } from '../../components/Menu';
import { Card } from '../Card';
import { Info } from '../../components/Info';
import { Modal } from '../../components/Modal';
import { Icon } from '../../components/Icon';
import {
  StickyColumn,
  TAB,
  APP_DISPLAY_NAME,
  DISTRICT_DATA_IMPORT,
  SCHOOL_DATA_IMPORT,
} from '../../constants';
import { getDistrict, getSchool, SchoolsStudentReadinessData, SRIRoles } from '../../api';
import { ProvisionStatus } from '../../../../interfaces/api';
import { Loader, LoaderItem } from '../../components/Loader';
import { Toastr, ToastrItem } from '../../components/Toastr';
import { ERROR_MESSAGE_TEXT } from '../../constants';
import { UserDetailsContext } from '../../App';
import { ExportDropdown } from '../../components/ExportDropdown';
import { FeatureFlagWrapper } from '../../components/FeatureFlagWrapper';
import { FeatureFlags } from '../../api/featureFlags';
import { DEFAULT_ROLL_OVER_DAY, DEFAULT_ROLL_OVER_MONTH } from '../../../admin/constants';

export interface Props {
  appDisplayName?: string;
  isDistrictLevel?: boolean;
  isDistrictGhostedAsSchoolUser?: boolean;
  districtId?: string;
  schoolId?: string;
  gotoManagePage: () => void;
  email?: string;
}

export const Page = (props: Props): JSX.Element => {
  (Page as React.FC).displayName = 'Page';
  const { appDisplayName, isDistrictLevel, gotoManagePage } = props;

  const getDefaultClassYear = (classYears: MenuItem[]): MenuItem => {
    let seniorYear: number;
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth(); // 0 based, so 6 is July
    const day = date.getDate();
    // succeed current senior year "rolls over" on July 25
    if (
      month > DEFAULT_ROLL_OVER_MONTH - 1 ||
      (month == DEFAULT_ROLL_OVER_MONTH - 1 && day > DEFAULT_ROLL_OVER_DAY)
    ) {
      seniorYear = year + 1;
    } else {
      seniorYear = year;
    }

    let selectedYear = 0;
    for (let i = 0; i < classYears.length; i++) {
      const yearInList: number = parseInt(classYears[i].key);
      if (yearInList < seniorYear) {
        selectedYear = yearInList;
      } else if (yearInList === seniorYear) {
        selectedYear = seniorYear;
        break;
      } else if (yearInList > seniorYear) {
        selectedYear = yearInList;
        break;
      }
    }

    return { key: `${selectedYear}`, value: `Class of ${selectedYear}` };
  };

  const [provisionStatus, setProvisionStatus] = useState<ProvisionStatus>(
    ProvisionStatus.NOT_REQUESTED
  );
  const [schoolName, setSchoolName] = useState<string>('');
  const [schools, setSchools] = useState<MenuItem[]>([]);
  const [counselors, setCounselors] = useState<MenuItem[]>([]);
  const [districtCounselors, setDistrictCounselors] = useState<Map<string, MenuItem[]>>();
  const [classYears, setClassYears] = useState<MenuItem[]>([]);
  const [isInfoVisible, setInfoVisible] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState<MenuItem>();
  const [selectedCounselor, setSelectedCounselor] = useState<MenuItem>();
  const [selectedClassYear, setSelectedClassYear] = useState<MenuItem>();
  const [dataRetrieved, setDataRetrieved] = useState(false); // loader state
  const [hasStudentReadinessData, setHasStudentReadinessData] = useState(false);
  const [hasImportedData, setHasImportedData] = useState(false);
  const [districtStudentsCount, setDistrictStudentsCount] = useState<number>(0);
  const [loader, setLoader] = useState<LoaderItem>({
    size: 'medium',
    loading: false,
  });
  const [toastr, setToastr] = useState<ToastrItem>({
    type: 'error',
    text: ERROR_MESSAGE_TEXT,
    show: false,
  });
  const [tab, setTab] = useState<string>(TAB.OVERVIEW);

  const { canManageSRIActivation, contactId, userType, canImportData } =
    useContext(UserDetailsContext);
  const showManageButton = canManageSRIActivation || false;
  const [stateName, setStateName] = useState<string>('');
  const [schoolOrCounselorAllTableExportColumns, setSchoolOrCounselorAllTableExportColumns] =
    useState<StickyColumn[]>([]);
  const [schoolOrCounselorTableExportData, setSchoolOrCounselorTableExportData] = useState<
    object[]
  >([]);
  const [studentReadinessDataSchools, setStudentReadinessDataSchools] = useState<
    SchoolsStudentReadinessData[]
  >([]);
  const exportRef: RefObject<any> = createRef();

  const retrieveDistrict = async () => {
    try {
      const {
        provisionStatus,
        schools,
        classYears,
        counselors,
        hasStudentReadinessData,
        hasImportedData,
        schoolsStudentReadinessData,
      } = await getDistrict();

      setProvisionStatus(provisionStatus);
      if (provisionStatus === ProvisionStatus.COMPLETED) {
        setDistrictCounselors(counselors);
        setSchools(schools);
        setSelectedSchool(schools[0]);
        setClassYears(classYears);
        setSelectedClassYear(getDefaultClassYear(classYears));
        setHasStudentReadinessData(hasStudentReadinessData);
        setHasImportedData(hasImportedData);
        setStudentReadinessDataSchools(schoolsStudentReadinessData);
      }
      setDataRetrieved(true);
      showLoader({ loading: false });
    } catch (error) {
      if (JSON.stringify(error).includes('404')) {
        setDataRetrieved(true);
        showLoader({ loading: false });
      } else {
        throw error;
      }
    }
  };

  const retrieveSchool = async () => {
    try {
      const {
        provisionStatus,
        classYears,
        counselors,
        schoolName,
        hasStudentReadinessData,
        hasImportedData,
      } = await getSchool();

      setProvisionStatus(provisionStatus);
      setSchoolName(schoolName);
      if (provisionStatus === ProvisionStatus.COMPLETED) {
        setCounselors(counselors);
        setSelectedCounselor(counselors[0]);
        setClassYears(classYears);
        setSelectedClassYear(getDefaultClassYear(classYears));
        setHasStudentReadinessData(hasStudentReadinessData);
        setHasImportedData(hasImportedData);
      }
      setDataRetrieved(true);
      showLoader({ loading: false });
    } catch (error) {
      if (JSON.stringify(error).includes('404')) {
        setDataRetrieved(true);
        showLoader({ loading: false });
      } else {
        throw error;
      }
    }
  };

  const getSelectedSchool: MenuItemOnClickFunction<MenuItem> = (selected: MenuItem) => {
    if (isDistrictLevel) {
      setCounselors(districtCounselors?.get(selected?.key || '') || []);
      const schoolHasReadiness = studentReadinessDataSchools?.find(
        (school) => school.id === selected?.key
      );
      if (schoolHasReadiness) {
        setHasStudentReadinessData(schoolHasReadiness.hasStudentReadinessData);
        setClassYears(schoolHasReadiness.classYears);
        const schoolClassYears: string[] = schoolHasReadiness.classYears.map(
          (item: MenuItem) => item.key
        );

        if (selectedClassYear?.key && !schoolClassYears.includes(selectedClassYear?.key)) {
          setSelectedClassYear(getDefaultClassYear(schoolHasReadiness.classYears));
        }
      }
    }
    schools && selected && setSelectedSchool(selected);
  };

  const getSelectedCounselor: MenuItemOnClickFunction<MenuItem> = (selected: MenuItem) => {
    counselors && selected && setSelectedCounselor(selected);
  };

  const getSelectedClassYear: MenuItemOnClickFunction<MenuItem> = (selected: MenuItem) => {
    classYears && selected && setSelectedClassYear(selected);
  };

  const toggleInfoVisible = () => {
    setInfoVisible(!isInfoVisible);
  };

  const getExportData = (
    stateName: string,
    allExportTableColumns: StickyColumn[],
    allExportTableData: object[]
  ) => {
    setStateName(stateName);
    setSchoolOrCounselorAllTableExportColumns(allExportTableColumns);
    setSchoolOrCounselorTableExportData(allExportTableData);
  };

  const getFilteredExportTableData = (
    filteredTableData: object[],
    tableCurrentColumns: StickyColumn[]
  ) => {
    exportRef?.current?.storeExportData(tableCurrentColumns, filteredTableData);
  };

  const showLoader = (loaderOps: LoaderItem) => {
    setLoader({
      ...loader,
      ...loaderOps,
    });
  };

  const showToastr = (toastrOps: ToastrItem) => {
    setToastr({
      ...toastr,
      ...toastrOps,
    });
  };

  const updateHasStudentReadinessData = () => {
    if (isDistrictLevel) {
      const studentReadinessIndex = studentReadinessDataSchools?.findIndex(
        (school) => school.id === selectedSchool?.key
      );
      if (studentReadinessIndex > -1) {
        // Update district hasStudentReadiness
        const districtReadinessIndex = studentReadinessDataSchools?.findIndex(
          (school) => school.id === '0'
        );
        if (districtReadinessIndex > -1) {
          studentReadinessDataSchools[districtReadinessIndex].hasStudentReadinessData = true;
        }
        studentReadinessDataSchools[studentReadinessIndex].hasStudentReadinessData = true;
        setStudentReadinessDataSchools(studentReadinessDataSchools);
      }
    }
    setHasStudentReadinessData(true);
  };

  const updateDistrictStudentsCount = (studentsCount: number) => {
    setDistrictStudentsCount(studentsCount);
  };

  useEffect(() => {
    showLoader({ loading: true });
    setDataRetrieved(false);
    isDistrictLevel ? retrieveDistrict() : retrieveSchool();
  }, []);

  useEffect(() => {
    if (counselors.length > 0 && contactId && userType === SRIRoles.RESTRICTED_COUNSELOR) {
      const counselor = counselors.find((counselor) => counselor.key === String(contactId));
      setSelectedCounselor(counselor);
    } else {
      setSelectedCounselor(counselors[0]);
    }
  }, [contactId, userType, counselors]);

  const provisioned = provisionStatus === ProvisionStatus.COMPLETED;
  const notProvisioned =
    provisionStatus === ProvisionStatus.IN_PROGRESS ||
    provisionStatus === ProvisionStatus.NOT_REQUESTED ||
    provisionStatus === ProvisionStatus.FAILED;
  const disabledExportDropdown =
    tab === TAB.OVERVIEW &&
    !hasStudentReadinessData &&
    isDistrictLevel &&
    selectedSchool?.key !== '0';

  return (
    <>
      {toastr.show && <Toastr type={toastr.type} text={toastr.text} />}
      {loader.loading && <Loader size={loader.size} timeout={loader.timeout} />}
      <div className="sri-main">
        <div className="sri-banner">
          <div className="sri-banner-left">
            <div className="sri-app-name-info">
              <Header appDisplayName={appDisplayName || 'Student Readiness Indicators'} />
              <Info showInfo={toggleInfoVisible} />
            </div>
            <Modal
              isVisible={isInfoVisible}
              toggleVisibility={toggleInfoVisible}
              standardCloseIcon={false}
              contentClassName={'main-info-modal-content'}
            >
              <div className="main-info-container">
                <div className="main-info-header">{APP_DISPLAY_NAME}</div>
                <div className="main-info-body">
                  This tool offers a consolidated view of your state&apos;s mandate
                  tracking.&nbsp;&nbsp;After the dashboard is activated, import student completion
                  data by leveraging the SRI import template found in&nbsp;
                  {canImportData ? (
                    <>
                      Setup&nbsp;&gt;&nbsp;
                      <a href={isDistrictLevel ? DISTRICT_DATA_IMPORT : SCHOOL_DATA_IMPORT}>
                        Data&nbsp;Import&nbsp;New
                      </a>
                      .
                    </>
                  ) : (
                    <>Setup&nbsp;&gt;&nbsp;Data&nbsp;Import&nbsp;New.</>
                  )}
                  <br />
                  <br />
                  Once student information is populated, review aggregate and individual
                  student&apos;s data in this dashboard.&nbsp;&nbsp;You can also manually edit
                  student completion status and export data for further review.
                </div>
                <div className="main-info-footer">
                  <div className="main-info-close" onClick={toggleInfoVisible}>
                    Close
                  </div>
                </div>
              </div>
            </Modal>
            {showManageButton && (
              <button type="button" className="banner-button" onClick={gotoManagePage}>
                <Icon name="gear" />
                <div className="manage-button-text">Manage</div>
              </button>
            )}
          </div>
          {dataRetrieved &&
            provisioned &&
            (hasStudentReadinessData ||
              (isDistrictLevel && selectedSchool?.key !== '0') ||
              tab === TAB.STUDENT_DETAILS) && (
              <div className="banner-dropdowns">
                {isDistrictLevel && schools && (
                  <Dropdown
                    list={schools}
                    selectedItem={selectedSchool}
                    menuItemOnClick={getSelectedSchool}
                  />
                )}
                {!isDistrictLevel && counselors && (
                  <Dropdown
                    list={counselors}
                    menuItemOnClick={getSelectedCounselor}
                    selectedItem={selectedCounselor}
                    disabled={userType === SRIRoles.RESTRICTED_COUNSELOR}
                  />
                )}
                {classYears && (
                  <div data-cy="classYearSelection">
                    <Dropdown
                      list={classYears}
                      menuItemOnClick={getSelectedClassYear}
                      selectedItem={
                        classYears.length
                          ? selectedClassYear
                          : { key: 'ClassYear', value: 'ClassYear' }
                      }
                      justifyRight
                      disabled={classYears.length == 0}
                    />
                  </div>
                )}
                <ExportDropdown
                  isDistrictLevel={isDistrictLevel}
                  ref={exportRef}
                  stateName={stateName}
                  schoolOrCounselorAllTableColumns={schoolOrCounselorAllTableExportColumns}
                  schoolOrCounselorAllTableData={schoolOrCounselorTableExportData}
                  selectedSchool={selectedSchool}
                  schoolName={schoolName}
                  selectedTab={tab}
                  numStudents={districtStudentsCount}
                  isDisabled={disabledExportDropdown || classYears.length == 0}
                  classYear={selectedClassYear?.key}
                />
              </div>
            )}
        </div>
        {dataRetrieved && (
          <FeatureFlagWrapper
            flagName={FeatureFlags.loadRulesByClassYear}
            propName="loadRulesByClassYear"
          >
            <Card
              isDistrictLevel={isDistrictLevel || false}
              selectedSchool={selectedSchool}
              selectedCounselor={selectedCounselor}
              selectedClassYear={selectedClassYear}
              hasStudentReadinessData={hasStudentReadinessData}
              hasImportedData={hasImportedData}
              schools={schools}
              counselors={counselors}
              showLoader={showLoader}
              showToastr={showToastr}
              notProvisioned={notProvisioned}
              gotoManagePage={gotoManagePage}
              showManageButton={showManageButton}
              getExportData={getExportData}
              getFilteredExportTableData={getFilteredExportTableData}
              onTabSelected={setTab}
              updateDistrictStudentsCount={updateDistrictStudentsCount}
              loaderState={loader.loading}
              updateHasStudentReadinessData={updateHasStudentReadinessData}
            />
          </FeatureFlagWrapper>
        )}
      </div>
    </>
  );
};
