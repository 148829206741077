import { AdminStateRulesResponse } from '../../../interfaces/api/stateRules';

export const DEFAULT_ROLL_OVER_DAY = 25;
export const DEFAULT_ROLL_OVER_MONTH = 7;

const { hostname } = location;
export const isLocal = hostname.includes('.local.') || hostname.includes('localhost');
export const isDev = hostname.includes('.dev.');
export const isQa = hostname.includes('.qa.');
export const isProd = hostname.includes('.prod.');
export const stackName = hostname.includes('.dev.')
  ? hostname.split('.dev.')[0].split('-')[1]
  : null;

export interface SriUser {
  name: string | undefined;
  email: string | undefined;
  access: any;
  preview: any;
  publish: any;
  env: string;
}

export type BadgeColor =
  | 'primary'
  | 'secondary'
  | 'accent'
  | 'ghost'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | undefined;

export interface ToastrItem {
  message?: string;
  status: 'info' | 'success' | 'warning' | 'error' | undefined;
  show?: boolean;
}

export type dropdownValue = {
  label: string | number;
  value: string | number;
};

export interface tHeaders {
  header: string;
  accessor: string;
}

export interface dataType {
  label: string;
  value: string;
  fields: Array<{ label: string; value: string }>;
}

export interface TestScoreRule {
  id: number;
  dataType: string;
  field: string;
  rule: string;
  value: string;
  conjunction: 'AND' | 'OR';
  fields?: dropdownValue[];
  fieldLabel?: string;
}

export enum DataTypeField {
  CALCULATED = 'calculated',
  DERIVED = 'derived',
  IMPORTED = 'imported',
}

export enum TableViewAction {
  DUPLICATE = 'Duplicate',
  EDIT = 'Edit',
  PREVIEW = 'Preview',
  PUBLISH = 'Publish',
  DELETE = 'Delete',
  PROMOTE = 'Promote',
  UNPUBLISH = 'Unpublish',
}

export interface tData extends AdminStateRulesResponse {
  dateCreated: string;
  statusLabel: string;
  datePublished: string;
  userCreated?: string;
  userUpdated?: string;
  badgeColor?: BadgeColor;
  actionItems?: TableViewAction[];
}

export interface ModelViewProps {
  modalTitle: string;
  modalBody: string;
  cancelButtonText: string;
  primaryButtonText: string;
  isPublish: boolean;
}

export const dataTypeDropdownFields: dropdownValue[] = [
  {
    label: 'Auto-Calculated - Test Score',
    value: DataTypeField.CALCULATED,
  },
  {
    label: 'Text - Derived',
    value: DataTypeField.DERIVED,
  },
  {
    label: 'Text - Imported',
    value: DataTypeField.IMPORTED,
  },
];

export const tableHeaders: tHeaders[] = [
  {
    header: 'State',
    accessor: 'state',
  },
  {
    header: 'Class Year',
    accessor: 'classYear',
  },
  {
    header: 'Name',
    accessor: 'name',
  },
  {
    header: 'Date Created',
    accessor: 'dateCreated',
  },
  {
    header: 'Date Published',
    accessor: 'datePublished',
  },
  {
    header: 'Status',
    accessor: 'status',
  },
  {
    header: 'Actions',
    accessor: '',
  },
];

export const states = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const years = (excludeYears: number[]) => {
  const today = new Date();
  //TODO:: check to see if we want june 15th check here, or only in tests...
  const currentYear =
    today.getMonth() < DEFAULT_ROLL_OVER_MONTH - 1 ||
    (today.getMonth() == DEFAULT_ROLL_OVER_MONTH - 1 && today.getDate() <= DEFAULT_ROLL_OVER_DAY)
      ? today.getFullYear()
      : today.getFullYear() + 1;
  const years = [];
  let i = 0;
  while (i < 26) {
    if (
      excludeYears.length == 0 ||
      (excludeYears.length && !excludeYears.includes(currentYear + i))
    ) {
      years.push({
        label: currentYear + i,
        value: currentYear + i,
      });
    }
    i++;
  }
  return years;
};

export const minChildrenIndicatorRequiredForMet = (n: number) => {
  return [...Array(n).keys()].map((value) => {
    return {
      label: value + 1,
      value: value + 1,
    };
  });
};

export const SYSTEM_ERROR_TIMEOUT = 20000;
export const ERROR_MESSAGE_TEXT =
  'Something went wrong. Try again. If the problem persists, please contact support.';
export const ALL_CLIENTS = 'ALL_CLIENTS';
export const SRI_PAGE_INFO = {
  Add: {
    title: 'Add SRI State Requirement',
    saveToastrMessage: 'SRI State Requirement Saved as Draft',
    previewDescBtnText: 'Add descriptions',
    saveAndCloseBtnText: 'Save and Close',
    saveBtnText: 'Save',
    descriptionsTitle: 'Add SRI descriptions',
  },
  Edit: {
    title: 'Edit SRI State Requirement',
    saveToastrMessage: 'SRI State Requirement Saved Successfully',
    previewDescBtnText: 'Edit descriptions',
    saveAndCloseBtnText: 'Save and Close',
    saveBtnText: 'Save',
    descriptionsTitle: 'Add SRI descriptions',
  },
  Preview: {
    title: 'Preview SRI State Requirement',
    saveToastrMessage: 'SRI State Requirement Saved as Draft',
    previewDescBtnText: 'Preview descriptions',
    saveAndCloseBtnText: 'Close',
    saveBtnText: 'Save',
    descriptionsTitle: 'Add SRI descriptions',
  },
};
export const PROMOTE_SUCCESS = 'Promoted';
export const PROMOTE_FAILED = 'PromoteFailed';
